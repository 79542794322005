.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.Nav {
    position: relative;
    background: #B554CC;
    background: linear-gradient(199.38deg, var(--color-violet-white) -8.82%, var(--color-secondary) 19.5%);
background-blend-mode: multiply;
    z-index: 200;
}
.Nav > input {
    margin: 0.5rem 1rem;
    width: -webkit-fill-available;
}

.header>p {
    font-size: 1.5em;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-top: 0px;
    display: inline;
}

.header {
    display: flex;
    padding: 25px;
    justify-content: space-between;
    color: rgb(255, 255, 255);
    align-items: center;
}

.header-left {
    display: flex;
    align-items: center;
}
.header-center{
    font-size: 1rem;
    font-weight: bold;
}

.header-center>h1 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 10px;
}

.header-right {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.subTitles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    width: -webkit-fill-available;
    font-weight: 700;
    font-size: 1rem;
    color: white;
}

.body-navbar {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc((100vh - 2rem) - 75px);
    /*  2rem = icons heigth, 75px = padding icons + navbarbottom*/
    background-color: rgb(255, 255, 255);
    z-index: 400;
}

.button {
    width: 80%;
    height: 50px;
    margin-block: 10px;
}

.active {
    background: #45348E;
    color: rgb(255, 255, 255);
}

.border-bottom {
    width: 100%;
    height: 25px;
    background: rgb(255, 255, 255);
    border-radius: 999px 999px 0px 0px;
    bottom: -3px;
    position: relative;
}

.navButton {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: .5rem 0;
}