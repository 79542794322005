:root {
  --color-primary: rgba(202, 24, 138, 1);
  --color-secondary: #45348E;
  --color-magenta-1: #7f268c;
  --color-violet-1: #572F90;
  --color-violet-2: #5D2F8E;
  --color-violet-3: #5E2F8E;
  --color-violet-white: #937CF4;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700&display=swap');

html {
  font-size: calc(.90em + 1vmin);
}

@media screen and (min-width: 768px) {
  html {
    font-size: calc(0.7em + 1vmin);
  }

}

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-border {
  border: none !important;
  height: auto !important;
}

.modal-text {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
}

.button-white {
  background-color: #fff !important;
  border: 1px solid #45348E !important;
  color: #45348E !important;
  cursor: pointer !important;
}

.themedButton {
  color: var(--color-secondary);
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--color-secondary);
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

::-webkit-scrollbar-button:increment, ::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;

  background-color: #f5f5f5;
}

::-webkit-scrollbar-corner {
  background-color: #ffffff00;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

.swal2-title{
  font-size: 1.6em !important;
}

.title-loader{
  color:#AAAAAA !important;
  font-size:25px !important;
  padding: 3em 2em 0 !important;
}
