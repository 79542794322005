.card{
    display: flex;
    flex-direction: column;
}

.CardTitleHistorial{
    color: #fff;
    font-weight: bold;
    margin-bottom: 2px;
    margin-top: 2px;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 17px;

}

.CardContentHistorial{
    display: flex;
    align-items: center;
    margin: 4px 0;
    color: #fff;
    font-size: 0.6rem;
}
 
.createdByHistorial{
    font-size: 0.7rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
}

.finalizacion{
    font-size: 0.6rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 5px;
}