.card{
    display: flex;
    flex-direction: column;
}

.CardTitle{
    font-size: 0.7rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 2px;
    margin-top: 2px;
    text-transform: capitalize;
}

.CardContent{
    display: flex;
    align-items: center;
    margin: 4px 0;
    color: #fff;
    font-size: 0.6rem;
}
 
.createdBy {
    font-size: 0.7rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
}

.hidden{
    display: none;
}