.form-login {
  min-height: 100vh;
  min-width: 288px;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  background: rgb(68, 48, 140);
  background: linear-gradient(199.38deg, var(--color-violet-white) -8.82%, var(--color-secondary) 19.5%);
  background-blend-mode: multiply;
}

form {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

label {
  display: block;
  width: 100%;
  text-align: left;
  color: white;
}

input {
  margin: 0.5rem 0;
  padding: 0.5rem;
  border: 0;
  border-radius: 0.3rem;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 1rem;
  border: 1px solid rgb(255, 255, 255);
  outline: none;
  width: 100%;
}

input::placeholder {
  color: rgb(255, 255, 255);
}

.btn {
  margin: 0.5rem 0;
  padding: 0.8rem;
  border: 0;
  border-radius: 0.5rem;
  background: rgba(68, 48, 140, 1);
  color: white;
  font-size: 1rem;
  width: 80%;
  outline: none;
  min-height: fit-content;
}

@media screen and (min-width: 600px) {
  .btn {
    width: 400px;
  }
}

.formgroup {
  width: 80%;
  display: inline-block;
  margin: 10px 0;
}

@media screen and (min-width: 600px) {
  .formgroup{
    width: 400px;
    margin: 1rem;
  }
}

.inputError {
  border: 1px solid red;
}

.labelError {
  color: rgb(255, 0, 0);
}

.textError {
  color: rgb(255, 0, 0);
  margin-top: -8px;
}

.iconPassword{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}

#password::-ms-reveal,
#password::-ms-clear {
  display: none;
}