.containerHistorial{
    margin: 0 0 30px 0
}
.containerCantidades{
    display: flex;
    padding-top: 20px;
}

.containerTotales{
    display: flex;
    padding-top: 7px;
}

.cardEstadistica{
    width: 50%;
    border: 1px solid var(--color-secondary);
    margin: 0px 10px 0px 0px;
    border-radius: 5px
}

.textoEstadistica {
    padding: 10px 10px 0 10px;
    margin: 0 0 0px 0;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
    background: -webkit-linear-gradient(271.23deg, #B554CC -3.94%, #45348E 104.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.textoDetalle {
    font-size: 12px;
    margin: 3px 0 3px 0;
    color: var(--color-secondary);
    text-align: center;
}